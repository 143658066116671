<template>
  <div>
    <div class="text-center">
      <!-- <h4>รอบ 2022-02-20</h4> -->
    </div>
    <v-simple-table class="px-1" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-start">
              <span class="text-secondary"> All Day </span>
            </th>
            <th class="text-start">
              <span class="text-secondary"> เวลา </span>
            </th>
            <th class="text-start">
              <span class="text-secondary"> จำนวนคน </span>
            </th>
            <th class="text-start">
              <span class="text-secondary"> จองแล้ว </span>
            </th>
            <th>
              <span class="text-secondary"> ตั้งค่า </span>
            </th>
            <th class="text-start">
              <span class="green--text"> เปิดรอบ </span>
            </th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in scheduleProps" :key="item.index">
            <template v-if="item.status !== 'deleted'">
              <td>
                <div style="width: 3rem">
                  <v-checkbox
                    @change="changeOptions($event, index)"
                    v-model="options[index]"
                  />
                </div>
              </td>
              <td>
                <v-row style="width: 15rem">
                  <v-col cols="6">
                    <v-text-field
                      v-if="options[index] == true"
                      value="AllDay"
                      disabled
                    ></v-text-field>
                    <v-text-field
                      v-else
                      :rules="notEmptyRules"
                      v-model="item.startTime"
                      type="time"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-if="options[index] == true"
                      value="AllDay"
                      disabled
                    ></v-text-field>
                    <v-text-field
                      v-else
                      :rules="notEmptyRules"
                      v-model="item.endTime"
                      type="time"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </td>
              <td>
                <v-col style="width: 12rem" cols="12">
                  <v-card outlined max-width="144" height="3rem">
                    <v-text-field
                      v-model="item.max"
                      class="centered-input"
                      type="number"
                      height="1.2rem"
                      prepend-icon="mdi-minus"
                      append-outer-icon="mdi-plus"
                      @click:prepend="decreaseNumber(index)"
                      @click:append-outer="increaseNumber(index)"
                    ></v-text-field>
                  </v-card>
                </v-col>
              </td>
              <td>
                <div style="width: 5rem" class="red--text">
                  {{ item.slot >= 0 ? item.slot : "-" }}
                </div>
              </td>
              <td>
                <div style="width: 3rem">
                  <DialogSetting :schedule="scheduleProps[index]" />
                </div>
              </td>
              <td>
                <v-switch color="success" v-model="item.isOpen" inset />
              </td>
              <!-- <td>
                <v-switch
                  :ripple="false"
                  color="success"
                  v-model="item.isOpen"
                  inset
                />
              </td>
              <td>
                <v-switch
                  :ripple="false"
                  color="success"
                  v-model="item.isOpen"
                  inset
                />
              </td> -->
              <td>
                <v-btn
                  v-if="item.status === 'active'"
                  color="green"
                  dark
                  :to="`/ticket/schedule/${item.scheduleId}`"
                  class="font-weight-bold"
                >
                  <v-icon left>mdi-printer</v-icon>
                  พิมพ์สายรัดข้อมือ
                </v-btn>
              </td>
              <td>
                <v-btn
                  v-if="item.slot <= 0 || !item.slot"
                  @click="removeSchedule(index)"
                  class="mx-2"
                  dark
                  color="red"
                >
                  <v-icon dark> mdi-delete </v-icon>
                  ลบ
                </v-btn>
              </td>
            </template>
            <template v-if="item.status === 'deleted'">
              <td class="opacity" style="width: 6rem">
                <v-checkbox
                  disabled
                  @change="changeOptions($event, index)"
                  v-model="options[index]"
                />
              </td>
              <td class="opacity">
                <v-row style="width: 14rem">
                  <v-col cols="6">
                    <v-text-field
                      v-if="options[index] == true"
                      value="AllDay"
                      disabled
                    ></v-text-field>
                    <v-text-field
                      v-else
                      :rules="notEmptyRules"
                      v-model="item.startTime"
                      type="time"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-if="options[index] == true"
                      value="AllDay"
                      disabled
                    ></v-text-field>
                    <v-text-field
                      v-else
                      :rules="notEmptyRules"
                      v-model="item.endTime"
                      type="time"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </td>
              <td class="opacity">
                <v-col cols="12">
                  <v-card outlined max-width="144" height="3rem">
                    <v-text-field
                      v-model="item.max"
                      class="centered-input"
                      disabled
                      color="red"
                      type="number"
                      height="1.2rem"
                      prepend-icon="mdi-minus"
                      append-outer-icon="mdi-plus"
                      @click:prepend="decreaseNumber(index)"
                      @click:append-outer="increaseNumber(index)"
                    ></v-text-field>
                  </v-card>
                </v-col>
              </td>
              <td class="opacity" style="width: 11rem">
                <span>{{ item.slot >= 0 ? item.slot : "-" }}</span>
              </td>
              <td class="opacity">-</td>
              <td class="opacity">
                <v-switch
                  class="red--text"
                  :ripple="false"
                  disabled
                  color="success"
                  v-model="item.isOpen"
                  inset
                />
              </td>
              <td>
                <v-btn
                  @click="restoreSchedule(index)"
                  class="mx-2"
                  dark
                  color="green"
                >
                  <v-icon dark> mdi-restart </v-icon>
                  กู้คืน
                </v-btn>
              </td>
              <td></td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import DialogSetting from "@/components/Booking/DialogSetting.vue";
export default {
  components: {
    DialogSetting,
  },
  props: {
    scheduleProps: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      isDialog: true,
      isSettingDialog: true,
      time: "",
      options: [],
    };
  },
  computed: {
    optionsSchedule() {
      return [
        { text: "Normal", value: "normal" },
        { text: "AllDay", value: "allDay" },
      ];
    },
    notEmptyRules() {
      return [(v) => !!v || "กรุณากรอกข้อมูล"];
    },
  },
  mounted() {
    for (let i = 0; i < this.scheduleProps.length; i++) {
      if (
        this.scheduleProps[i].startTime === "00:00" &&
        this.scheduleProps[i].endTime === "23:59"
      ) {
        this.options.push(true);
      } else {
        this.options.push(false);
      }
    }
  },
  methods: {
    addSchedule(index) {
      this.scheduleProps.splice(index + 1, 0, {
        startTime: "",
        endTime: "",
        max: 10,
        isOpen: true,
        options: {
          price: {
            adult: {
              price: 0,
            },
            young: {
              price: 0,
            },
            type: "allFree",
          },
        },
      });
    },
    removeSchedule(index) {
      if (!this.scheduleProps[index].status) {
        this.scheduleProps.splice(index, 1);
        return;
      }
      this.scheduleProps[index].status = "deleted";
    },
    restoreSchedule(index) {
      this.scheduleProps[index].status = "active";
    },
    increaseNumber(index) {
      this.scheduleProps[index].max = this.scheduleProps[index].max + 1;
    },
    decreaseNumber(index) {
      if (this.scheduleProps[index].max > 0) {
        this.scheduleProps[index].max = this.scheduleProps[index].max - 1;
      }
    },
    changeOptions(event, index) {
      if (event == true) {
        this.scheduleProps[index].startTime = "00:00";
        this.scheduleProps[index].endTime = "23:59";
      } else {
        this.scheduleProps[index].startTime = "";
        this.scheduleProps[index].endTime = "";
      }
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
.opacity {
  opacity: 0.6;
}
</style>
