<template>
  <div>
    <div class="text-center">
      <!-- <h4>รอบ 2022-02-20</h4> -->
    </div>
    <v-simple-table class="px-1" dense>
      <template v-slot:default>
        <thead>
          <tr v-if="type === 'add'">
            <th class="text-start">
              <span class="text-secondary"> All Day </span>
            </th>
            <th class="text-start">
              <span class="text-secondary"> เวลา </span>
            </th>
            <th class="text-start">
              <span class="text-secondary"> จำนวนคน </span>
            </th>
            <th>
              <span class="text-secondary"> ตั้งค่า </span>
            </th>
            <th class="text-start">
              <span class="green--text"> เปิดรอบ </span>
            </th>
            <th></th>
          </tr>
          <tr v-if="type === 'edit'">
            <th class="text-start">
              <span class="text-secondary"> All Day </span>
            </th>
            <th class="text-start">
              <span class="text-secondary"> เวลาเดิม </span>
            </th>
            <th class="text-start">
              <span class="text-secondary"> เวลาใหม่ </span>
            </th>
            <th class="text-start">
              <span class="text-secondary"> จำนวนคน </span>
            </th>
            <th>
              <span class="text-secondary"> ตั้งค่า </span>
            </th>
            <th class="text-start">
              <span class="green--text"> เปิดรอบ </span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in scheduleProps" :key="item.index">
            <template v-if="item.status !== 'deleted'">
              <td style="width: 5rem" v-if="type === 'add'">
                <v-checkbox
                  v-model="options[index]"
                  :disabled="item.oldData"
                  @change="changeOptions($event, index)"
                />
              </td>
              <td style="width: 5rem" v-if="type === 'edit'">
                <v-checkbox
                  v-model="options[index]"
                  @change="changeOptions($event, index, 'edit')"
                />
              </td>
              <td v-if="type === 'add'">
                <v-row style="width: 13rem">
                  <v-col cols="6">
                    <v-text-field
                      v-if="options[index] == true"
                      value="AllDay"
                      :disabled="item.oldData || options[index] == true"
                    ></v-text-field>
                    <v-text-field
                      :disabled="item.oldData"
                      v-else
                      type="time"
                      v-model="item.startTime"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-if="options[index] == true"
                      value="AllDay"
                      :disabled="item.oldData || options[index] == true"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      v-model="item.endTime"
                      :disabled="item.oldData"
                      type="time"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </td>
              <td v-if="type === 'edit'">
                <v-row style="width: 13rem">
                  <v-col cols="6">
                    <v-text-field
                      v-if="options[index] == true"
                      disabled
                      value="Allday"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      type="time"
                      disabled
                      v-model="item.startTime"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-if="options[index] == true"
                      disabled
                      value="Allday"
                    ></v-text-field>

                    <v-text
                      v-else
                      v-model="item.endTime"
                      type="time"
                      disabled
                    ></v-text>
                  </v-col>
                </v-row>
              </td>
              <td v-if="type === 'edit'">
                <v-row style="width: 13rem">
                  <v-col cols="6">
                    <v-text-field
                      v-if="options[index] == true"
                      value="AllDay"
                      disabled
                    ></v-text-field>
                    <v-text-field
                      v-else
                      v-model="item.newStartTime"
                      type="time"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-if="options[index] == true"
                      value="AllDay"
                      disabled
                    ></v-text-field>
                    <v-text-field
                      v-else
                      v-model="item.newEndTime"
                      type="time"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </td>
              <td>
                <v-col style="width: 13rem" cols="12">
                  <span v-if="item.oldData" class="font-weight-bold green--text"
                    >-</span
                  >
                  <v-card v-else outlined max-width="144" height="3rem">
                    <v-text-field
                      v-model="item.max"
                      class="centered-input"
                      height="1.2rem"
                      prepend-icon="mdi-minus"
                      append-outer-icon="mdi-plus"
                      @click:prepend="decreaseNumber(index)"
                      @click:append-outer="increaseNumber(index)"
                    ></v-text-field>
                  </v-card>
                </v-col>
              </td>
              <td>
                <span v-if="item.oldData" class="font-weight-bold green--text"
                  >-</span
                >
                <DialogSetting v-else :schedule="scheduleProps[index]" />
              </td>
              <td>
                <span v-if="item.oldData" class="font-weight-bold green--text"
                  >-</span
                >
                <v-switch
                  v-else
                  :ripple="false"
                  color="success"
                  v-model="item.isOpen"
                  inset
                />
              </td>
              <td>
                <span v-if="item.oldData" class="font-weight-bold red--text"
                  >รอบเวลาที่เคยถูกสร้างไว้แล้ว</span
                >
                <v-btn
                  v-if="!item.oldData"
                  @click="removeSchedule(index)"
                  class="mx-2"
                  dark
                  color="red"
                >
                  <v-icon dark> mdi-delete </v-icon>
                  ลบ
                </v-btn>
              </td>
            </template>
            <template v-if="item.status === 'deleted'">
              <td class="opacity">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      :rules="notEmptyRules"
                      disabled
                      v-model="item.startTime"
                      type="time"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      :rules="notEmptyRules"
                      disabled
                      v-model="item.endTime"
                      type="time"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </td>
              <td class="opacity">
                <v-col cols="12">
                  <v-card outlined max-width="144" height="3rem">
                    <v-text-field
                      v-model="item.max"
                      class="centered-input"
                      disabled
                      color="red"
                      type="number"
                      height="1.2rem"
                      prepend-icon="mdi-minus"
                      append-outer-icon="mdi-plus"
                      @click:prepend="decreaseNumber(index)"
                      @click:append-outer="increaseNumber(index)"
                    ></v-text-field>
                  </v-card>
                </v-col>
              </td>
              <td class="opacity">
                <DialogSetting
                  :disabled="true"
                  :schedule="scheduleProps[index]"
                />
              </td>
              <td class="opacity">
                <v-switch
                  class="red--text"
                  :ripple="false"
                  disabled
                  color="success"
                  v-model="item.isOpen"
                  inset
                />
              </td>
              <td></td>
              <td>
                <v-btn
                  @click="restoreSchedule(index)"
                  class="mx-2"
                  dark
                  color="green"
                >
                  <v-icon dark> mdi-restart </v-icon>
                  กู้คืน
                </v-btn>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import DialogSetting from "@/components/Booking/DialogSetting.vue";

export default {
  components: {
    DialogSetting,
  },
  props: {
    scheduleProps: {
      type: Array,
    },
    oldData: {
      type: Array,
    },
    type: {
      type: String,
      default: "edit",
    },
  },
  computed: {
    notEmptyRules() {
      return [(v) => !!v || "กรุณากรอกข้อมูล"];
    },
  },
  data() {
    return {
      loading: false,
      isDialog: true,
      isSettingDialog: true,
      options: [],
      time: "",
    };
  },
  mounted() {
    for (let i = 0; i < this.scheduleProps.length; i++) {
      if (
        this.scheduleProps[i].startTime === "00:00" &&
        this.scheduleProps[i].endTime === "23:59"
      ) {
        this.options.push(true);
      } else {
        this.options.push(false);
      }
    }
  },
  methods: {
    addSchedule(index) {
      this.scheduleProps.splice(index + 1, 0, {
        startTime: "",
        endTime: "",
        max: 10,
        isOpen: true,
        options: {
          price: {
            adult: {
              price: 0,
            },
            young: {
              price: 0,
            },
            type: "allFree",
          },
        },
      });
    },
    removeSchedule(index) {
      if (!this.scheduleProps[index].status) {
        this.scheduleProps.splice(index, 1);
        return;
      }
      this.scheduleProps[index].status = "deleted";
    },
    restoreSchedule(index) {
      this.scheduleProps[index].status = "active";
    },
    increaseNumber(index) {
      this.scheduleProps[index].max = this.scheduleProps[index].max + 1;
    },
    decreaseNumber(index) {
      if (this.scheduleProps[index].max > 0) {
        this.scheduleProps[index].max = this.scheduleProps[index].max - 1;
      }
    },
    changeOptions(event, index, type) {
      if (type === "edit") {
        if (event == true) {
          this.scheduleProps[index].newStartTime = "00:00";
          this.scheduleProps[index].newEndTime = "23:59";
        } else {
          this.scheduleProps[index].newStartTime = "";
          this.scheduleProps[index].newEndTime = "";
        }
      } else {
        if (event == true) {
          this.scheduleProps[index].startTime = "00:00";
          this.scheduleProps[index].endTime = "23:59";
        } else {
          this.scheduleProps[index].startTime = "";
          this.scheduleProps[index].endTime = "";
        }
      }
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
