<template>
  <v-container :class="classObject">
    <Loading v-if="loading" />
    <ConfirmDialog ref="confirmDialogue" />
    <v-row class="mt-2">
      <v-col cols="12" class="py-0">
        <h2 class="text-secondary header">จัดการหน้ากิจกรรม</h2>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12" sm="6" lg="5" class="py-0">
        <v-breadcrumbs divider=">" :items="breadItem" class="breadcrumbs">
        </v-breadcrumbs>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card outlined elevation="0">
          <v-card-title style="background-color: #8acef5">
            <h5 class="white--text">การจัดการ</h5>
          </v-card-title>

          <v-container class="px-5">
            <v-row class="px-2">
              <v-col cols="6">
                <v-select
                  outlined
                  item-text="text"
                  item-value="value"
                  label="เลือกเวลา"
                  v-model="type"
                  :items="select.type"
                  row
                  @input="selectType($event)"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  :items="branchItems"
                  outlined
                  item-text="text"
                  item-value="value"
                  label="สาขา"
                  v-model="selected.branch"
                  @input="getScheduleByDate()"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="px-2">
              <v-col v-if="type === 'day'" cols="12">
                <DatePicker
                  reactive
                  :min="min"
                  v-model="date"
                  :events="fullEvents"
                  @change="setDate($event)"
                />
              </v-col>
              <v-col v-if="type === 'multiple'" cols="12">
                <DatePicker
                  multiple
                  v-model="dateMultiple"
                  :selected-items-text="showTextMultiple"
                  :min="min"
                  :events="fullEvents"
                  @input="getDateMultiple($event)"
                  @change="getDateMultiple($event)"
                />
              </v-col>
              <v-col v-if="type === 'range'" cols="12">
                <DatePicker
                  v-model="dateRange"
                  range
                  selected-items-text=""
                  :min="min"
                  :events="fullEvents"
                  @input="getDateRange($event)"
                />
              </v-col>
            </v-row>

            <v-row v-if="type !== 'day'">
              <v-col cols="12">
                <div class="d-flex justify-center">
                  <v-radio-group
                    @change="changeRadio($event)"
                    v-model="multiple.action"
                    row
                  >
                    <v-radio label="เพิ่ม" value="add"></v-radio>
                    <v-radio label="แก้ไข / ลบ" value="edit"></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>
            <div class="my-10">
              <v-tabs v-model="tab">
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab
                  v-for="(item, index) in tabItems"
                  :key="index"
                  @change="changeTabs(`${item.value}`)"
                >
                  {{ item.text }}
                </v-tab>
              </v-tabs>
              <v-row
                v-if="scheduleType == 'event' && isLoadingButton === false"
              >
                <v-col cols="12">
                  <div class="d-flex justify-end">
                    <EventSettingDialog
                      v-if="isEventDialog"
                      @input="onSaveEvent($event)"
                      @getEvent="getEvent()"
                      @delete="onDeleteEvent($event)"
                      :event="event"
                      :date="date"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-tabs-items class="mt-5" v-model="tab">
                <v-tab-item v-for="(item, index) in tabItems" :key="index">
                  <TableBooking
                    v-if="showTable && type !== 'day'"
                    :type="multiple.action"
                    :scheduleProps="schedule"
                    :oldData="oldData"
                  />
                  <book-table
                    v-if="showTable && type === 'day'"
                    @input="onSubmitEdit($event)"
                    :scheduleProps="schedule"
                  />
                </v-tab-item>
              </v-tabs-items>
            </div>
            <!-- <v-row>
              <v-col cols="12">
                <TableBooking
                  v-if="showTable && type !== 'day'"
                  :type="multiple.action"
                  :scheduleProps="schedule"
                  :oldData="oldData"
                />
                <book-table
                  v-if="showTable && type === 'day'"
                  @input="onSubmitEdit($event)"
                  :scheduleProps="schedule"
                />
              </v-col>
            </v-row> -->
          </v-container>
          <div
            v-if="schedule.length <= 0 && type !== 'day'"
            class="d-flex justify-center my-4"
          >
            <span v-if="multiple.action === 'edit'" class="font-weight-bold"
              >ไม่พบข้อมูลการสร้างรอบการจอง</span
            >
          </div>
          <div
            v-if="
              multiple.action === 'add' &&
              type === 'day' &&
              schedule.length <= 0
            "
            class="d-flex justify-center my-4"
          >
            <span class="font-weight-bold"
              >ไม่พบรอบการจองที่ถูกสร้างไว้ หากต้องการเพิ่มรอบการจอง กรุณากด
              <span
                class="primary--text cursor-pointer"
                @click="addSchedule(schedule.length)"
              >
                "เพิ่มรอบ"
              </span>
            </span>
          </div>
          <div
            class="d-flex justify-center my-4"
            v-if="
              multiple.action === 'add' &&
              type !== 'day' &&
              schedule.length <= 0
            "
          >
            <span class="font-weight-bold px-13"
              ><span class="red--text">* ข้อควรระวัง</span> การเพิ่มรอบแบบ
              <span class="red--text">"เลือกหลายรายการ" </span> และ
              <span class="red--text"> "เลือกเป็นช่วงเวลา" </span>
              เป็นการเพิ่มรอบขึ้นมาใหม่โดยไม่สนใจว่าวันนั้นมีการสร้างรอบการจองเวลานี้อยู่หรือไม่
              ควรตรวจสอบให้แน่ใจก่อนการสร้างรอบเวลาใหม่ทุกครั้ง</span
            >
          </div>
        </v-card>
        <v-row
          v-if="schedule.length > 0 && multiple.action === 'add'"
          class="mt-4 mb-6"
        >
          <v-col cols="6">
            <v-btn
              :loading="isLoadingButton"
              color="primary"
              block
              class="font-weight-bold"
              @click="addSchedule(schedule.length)"
              >เพิ่มรอบ</v-btn
            >
          </v-col>
          <v-col cols="6">
            <v-btn
              :loading="isLoadingButton"
              color="success"
              block
              class="font-weight-bold"
              @click="submitSchedule()"
              >บันทึก</v-btn
            >
          </v-col>
        </v-row>
        <v-row
          v-if="schedule.length <= 0 && multiple.action === 'add'"
          class="mt-4 mb-6"
        >
          <v-col cols="12">
            <v-btn
              :loading="isLoadingButton"
              color="primary"
              block
              class="font-weight-bold"
              @click="addSchedule(schedule.length)"
              >เพิ่มรอบ</v-btn
            >
          </v-col>
        </v-row>
        <v-row
          v-if="multiple.action === 'edit' && schedule.length > 0"
          class="mt-4 mb-6"
        >
          <v-col cols="12">
            <v-btn
              :loading="isLoadingButton"
              color="success"
              block
              class="font-weight-bold"
              @click="submitSchedule()"
              >บันทึก</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "../../../utils/axios.js";
import dayjs from "dayjs";
import Loading from "@/components/Loading.vue";
import DatePicker from "@/components/common/DatePicker.vue";
import EventSettingDialog from "@/components/Booking/EventSettingDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import TableBooking from "@/components/Booking/TableBooking.vue";
// import BookActivitiesCombobox from "../components/book-management/Book-activities-combobox.vue";
// import BookDatepickerMultiple from "../components/book-management/Book-datepicker-multiple.vue";
// import BookDatepicker from "../components/book-management/Book-datepicker.vue";
import BookTable from "../components/book-management/Book-table.vue";
export default {
  components: {
    BookTable,
    ConfirmDialog,
    EventSettingDialog,
    DatePicker,
    Loading,
    TableBooking,
  },
  data() {
    return {
      tab: null,
      isEventDialog: true,
      isConfirmDialog: true,
      showTable: true,
      isLoadingButton: false,
      loading: false,
      isAlready: false,
      reloaded: true,
      fullEvents: [],
      event: null,
      options: [],
      ageRange: [],
      oldData: [],
      date: dayjs().format("YYYY-MM-DD"),
      type: "day",
      dateRange: [],
      multiple: {
        action: "add",
      },
      dateMultiple: [],
      startDate: dayjs().format("YYYY-MM-DD"),
      endDate: new Date().getDate() + 1,
      days: [
        "จันทร์",
        "อังคาร",
        "พุธ",
        "พฤหัสบดี",
        "ศุกร์",
        "เสาร์",
        "อาทิตย์",
      ],
      typeAllDay: null,
      select: {
        type: [
          { text: "รายวัน", value: "day" },
          { text: "เลือกหลายรายการ", value: "multiple" },
          { text: "เลือกเป็นช่วงเวลา", value: "range" },
        ],
      }, // ประเภทการจอง,
      selected: {
        branch: "",
      },
      breadItem: [
        {
          text: "จัดการหน้ากิจกรรม",
          disabled: true,
        },
      ],
      schedule: [],
      scheduleType: "normal",
      tabItems: [
        { text: "ปกติ", value: "normal" },
        { text: "กิจกรรม", value: "event" },
      ],
    };
  },
  methods: {
    async getSetting() {
      try {
        const {
          data: { data },
        } = await axios.get(`/setting?branch=${this.selected.branch}`);
        if (data && data[0].ageRange.length > 0) {
          this.ageRange = data[0].ageRange;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async onDeleteEvent(event) {
      try {
        this.loading = true;
        await axios.delete(`/event/${event}`);
        this.getEvent();
        this.alertSuccess("ดำเนินการสำเร็จ");
        this.$forceUpdate();
      } catch (error) {
        this.alertError("ดำเนินไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async changeTabs(event) {
      try {
        this.type = "day";
        this.scheduleType = event;
        await this.getScheduleByDate();
      } catch (error) {
        console.error(error);
      }
    },
    async getScheduleByDate() {
      this.isLoadingButton = true;
      this.showTable = false;
      try {
        const {
          data: { data },
        } = await axios({
          method: "GET",
          url: `/schedule/date/search?date=${this.date}&branch=${this.selected.branch}&type=${this.scheduleType}`,
        });

        if (data.length > 0) {
          this.schedule = [...data];
          this.isAlready = true;
        }
        if (data.length <= 0) {
          this.schedule = [];
        }
        await this.getScheduleMonth();
        this.showTable = true;
      } catch (error) {
        this.alertError("เกิดข้อผิดพลาด ดำเนินการไม่สำเร็จ");
      } finally {
        this.isLoadingButton = false;
      }
    },
    async getScheduleMonth() {
      try {
        // const { data } = await axios({
        //   method: "GET",
        //   url: `/schedule/date/month?month=${dayjs().format(
        //     "M"
        //   )}&year=${dayjs().format("YYYY")}&branch=${
        //     this.selected.branch
        //   }&type=${this.scheduleType}`,
        // });
        const { data } = await axios({
          method: "GET",
          url: `/schedule/date/month?branch=${this.selected.branch}&type=${this.scheduleType}`,
        });

        const arr = [];

        for (let i = 0; i < data?.additional?.available?.length; i++) {
          arr.push({ color: "red", date: data.additional.available[i] });
        }
        for (let i = 0; i < data?.additional?.full?.length; i++) {
          arr.push({ color: "blue", date: data.additional.full[i] });
        }
        this.fullEvents = arr;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoadingButton = false;
      }
    },
    async getDateMultiple() {
      try {
        const type = this.multiple.action;
        if (type === "edit") {
          const {
            data: { data },
          } = await axios.post(
            `/schedule/${this.selected.branch}/multiple?type=${this.scheduleType}`,
            {
              date: this.dateMultiple,
            }
          );
          const arr = data.filter((item, index, arr) => {
            return (
              index ===
              arr.findIndex(
                (t) =>
                  t.startTime === item.startTime && t.endTime === item.endTime
              )
            );
          });
          for (let i = 0; i < arr.length; i++) {
            arr[i].newStartTime = arr[i].startTime;
            arr[i].newEndTime = arr[i].endTime;
            arr[i].branch = this.selected.branch;
          }
          this.schedule = [...arr];
        }
        if (type === "add") {
          const {
            data: { data },
          } = await axios.post(
            `/schedule/${this.selected.branch}/multiple?type=${this.scheduleType}`,
            {
              date: this.dateMultiple,
            }
          );
          const arr = data.filter((item, index, arr) => {
            return (
              index ===
              arr.findIndex(
                (t) =>
                  t.startTime === item.startTime && t.endTime === item.endTime
              )
            );
          });
          for (let i = 0; i < arr.length; i++) {
            arr[i].oldData = true;
            arr[i].branch = this.selected.branch;
          }
          this.schedule = [...arr];
        }
        this.showTable = false;
        this.$nextTick(() => {
          this.showTable = true;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getDateRange() {
      try {
        if (this.dateRange.length === 2 && this.multiple.action === "edit") {
          const {
            data: { data },
          } = await axios.post(
            `/schedule/${this.selected.branch}/range?type=${this.scheduleType}`,
            {
              date: this.dateRange,
            }
          );
          const arr = data.filter((item, index, arr) => {
            if (index === 0) {
              return true;
            }
            return (
              index ===
              arr.findIndex(
                (t) =>
                  t.startTime === item.startTime && t.endTime === item.endTime
              )
            );
          });
          for (let i = 0; i < arr.length; i++) {
            arr[i].branch = this.selected.branch;
            arr[i].newStartTime = arr[i].startTime;
            arr[i].newEndTime = arr[i].endTime;
          }

          this.schedule = [...arr];
        }
        if (this.dateRange.length === 2 && this.multiple.action === "add") {
          const {
            data: { data },
          } = await axios.post(
            `/schedule/${this.selected.branch}/range?type=${this.scheduleType}`,
            {
              date: this.dateRange,
            }
          );
          const arr = data.filter((item, index, arr) => {
            if (index === 0) {
              return true;
            }
            return (
              index ===
              arr.findIndex(
                (t) =>
                  t.startTime === item.startTime && t.endTime === item.endTime
              )
            );
          });
          for (let i = 0; i < arr.length; i++) {
            arr[i].oldData = true;
            arr[i].branch = this.selected.branch;
          }

          this.schedule = [...arr];
        }
        this.showTable = false;
        this.$nextTick(() => {
          this.showTable = true;
        });
      } catch (error) {
        console.error(error);
      }
    },
    addSchedule(index) {
      this.schedule.splice(index + 1, 0, {
        startTime: "",
        endTime: "",
        max: 10,
        isOpen: true,
        type: this.scheduleType,
        options: {
          price: {
            young: this.ageRange[0].price,
            student: this.ageRange[1].price,
            adult: this.ageRange[2].price,
            senior: this.ageRange[3].price,
            type: "allPrice",
          },
          promotion: {
            percent: 0,
          },
        },
      });
    },
    changeRadio(data) {
      // if (data === "add") this.schedule = [];
      if (data === "add" && this.type === "multiple") {
        this.getDateMultiple();
      }
      if (data === "add" && this.type === "range") {
        this.getDateRange();
      }
      if (data === "edit" && this.type === "multiple") {
        this.getDateMultiple();
      }
      if (data === "edit" && this.type === "range") {
        this.getDateRange();
      }
    },
    selectType(data) {
      this.dateRange = [];
      this.dateMultiple = [];
      this.schedule = [];
      if (data === "day") {
        this.multiple.action = "add";
        this.date = dayjs().format("YYYY-MM-DD");
        this.getScheduleByDate();
      }
    },
    async setStartDate(date) {
      try {
        this.startDate = date;
        if (this.startDate && this.endDate) {
          await axios.get(
            `/schedule/date/search?startDate=${this.startDate}&endDate=${this.endDate}`
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    async setEndDate(date) {
      try {
        this.endDate = date;
      } catch (error) {
        console.error(error);
      }
    },
    setDate(date) {
      this.date = date;
      this.getScheduleByDate();
    },
    async submitSchedule() {
      try {
        this.loading = true;
        const schedule = this.schedule;
        if (schedule.filter((item) => item.status === "deleted").length > 0) {
          const confirm = await this.$refs.confirmDialogue.show();
          if (!confirm) return "";
        }
        if (this.type === "multiple") await this.multipleSubmit();
        if (this.type === "range") await this.rangeSubmit();

        if (this.type !== "multiple" && this.type !== "range") {
          let payload = `/schedule?startDate=${this.startDate}&endDate=${this.endDate}&branch=${this.selected.branch}`;
          if (this.type === "day") {
            payload = `/schedule?dates=${this.date}&branch=${this.selected.branch}`;
          }
          const data = [];
          this.schedule.forEach((item) => {
            data.push({
              scheduleId: item.scheduleId,
              date: this.date,
              startTime: item.startTime,
              endTime: item.endTime,
              max: item.max,
              type: item.type,
              isOpen: item.isOpen,
              status: item.status,
              branch: this.selected.branch,
              options: item.options,
            });
          });
          const result = await axios.post(`${payload}`, data);
          this.schedule = [...result.data.data];
          await this.getScheduleByDate();
        }
        this.alertSuccess("บันทึกข้อมูลสำเร็จ");
      } catch (error) {
        this.alertError("เกิดข้อผิดพลาด ดำเนินการไม่สำเร็จ");
      } finally {
        this.loading = false;
      }
    },
    async multipleSubmit() {
      if (this.multiple.action === "add") {
        const arr = this.schedule;
        const newArr = arr.filter((item) => !item.oldData);
        for (let i = 0; i < newArr.length; i++) {
          newArr[i].branch = this.selected.branch;
        }
        const payload = {
          date: this.dateMultiple,
          payload: newArr,
        };
        await axios.post(`/schedule/multiple`, payload);
      }
      if (this.multiple.action === "edit") {
        const payload = {
          date: this.dateMultiple,
          payload: this.schedule,
        };
        await axios.patch(`/schedule/multiple`, payload);
      }
      this.getDateMultiple();
    },
    async rangeSubmit() {
      if (this.multiple.action === "add") {
        const arr = this.schedule;
        const newArr = arr.filter((item) => !item.oldData);
        for (let i = 0; i < newArr.length; i++) {
          newArr[i].branch = this.selected.branch;
        }
        const payload = {
          date: this.dateRange,
          payload: newArr,
        };
        // console.log(payload);
        await axios.post(`/schedule/range`, payload);
      }
      if (this.multiple.action === "edit") {
        const payload = {
          date: this.dateRange,
          payload: this.schedule,
        };
        await axios.patch(`/schedule/range`, payload);
      }
      this.getDateRange();
    },
    async getEvent() {
      try {
        this.loading = true;
        this.reloaded = false;
        const {
          data: { data },
        } = await axios.get(
          `/event/${this.date}/date?branch=${this.selected.branch}`
        );
        if (data.length > 0) {
          this.event = data;
        } else {
          this.event = null;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.reloaded = true;
      }
    },
    async onSaveEvent(event) {
      try {
        this.loading = true;
        const date = dayjs(this.date).format("YYYY-MM-DD");
        const branch = this.selected.branch;
        const result = event.map((item) => {
          item.date = date;
          item.branch = branch;
          return item;
        });
        for (let i = 0; i < result.length; i++) {
          for (let j = 0; j < result[i].images.length; j++) {
            if (result[i].images[j]) {
              if (typeof result[i].images[j] === "object") {
                const formData = new FormData();
                formData.append("file", result[i].images[j]);
                console.log("2");
                const {
                  data: {
                    data: { publicUrl },
                  },
                } = await axios.post("/upload", formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                });
                result[i].images[j] = publicUrl;
                formData.delete("file");
              }
            }
          }
          await axios.post("/event", result[i]);
        }
        await this.getEvent();
        this.isEventDialog = false;
        this.$nextTick(() => {
          this.isEventDialog = true;
        });
        this.alertSuccess("ดำเนินการสำเร็จ");
      } catch (error) {
        console.error(error);
        this.alertError("ดำเนินไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.loading = false;
      }
    },
    alertSuccess(text) {
      this.$toast.success(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
    alertError(text) {
      this.$toast.error(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
  },
  async mounted() {
    const branch =
      this.info.profile.branch === "all" ? "trang" : this.info.profile.branch;
    this.selected.branch = branch;
    await this.getSetting();
    await this.getScheduleByDate();
    await this.getScheduleMonth();
    // setTimeout(() => {
    //   this.selected = this.info.profile.branch;
    // }, 1000);
  },
  computed: {
    ...mapGetters({
      info: "User/info",
    }),
    branchItems() {
      if (this.info.profile.branch === "trang") {
        return [{ text: "ตรัง", value: "trang" }];
      }
      if (this.info.profile.branch === "sathorn") {
        return [{ text: "สาทร", value: "sathorn" }];
      }
      if (this.info.profile.branch === "all") {
        return [
          { text: "ตรัง", value: "trang" },
          { text: "สาทร", value: "sathorn" },
        ];
      }
      return [{ text: "", value: "" }];
    },
    showTextMultiple() {
      return this.dateMultiple.length + " เลือกแล้ว";
    },
    min() {
      return new Date().toISOString();
    },
    minForEnd() {
      const d = new Date(this.startDate || new Date());
      d.setDate(d.getDate() + 1);
      const a = d.toISOString();
      return a;
    },
    classObject() {
      if (this.$vuetify.breakpoint.width > 960) {
        return "mt-0";
      } else {
        return "mt-5";
      }
    },
    radioAxis() {
      if (this.$vuetify.breakpoint.width > 960) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-radio.theme--light > label {
  font-size: 0.9rem;
}

.bg-primary {
  background-color: #00bcd4 !important;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
