<template>
  <div>
    <v-date-picker
      @change="showMonth($event)"
      :events="event"
      event-color="red"
      v-bind="attrs"
      v-on="{ ...$listeners }"
    >
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    </v-date-picker>
  </div>
</template>
<script>
export default {
  props: {
    events: {
      type: Array,
      default: () => ["2022-05-25", "2022-05-26"],
    },
  },
  mounted() {},
  data() {
    return {
      defaultAttrs: {
        color: "#3db2ff",
        locale: "th",
        fullWidth: true,
        elevation: 10,
      },
    };
  },
  methods: {
    showMonth(month) {
      console.log(month);
    },
  },
  computed: {
    event() {
      const arr = [];
      for (let i = 0; i < this.events.length; i++) {
        arr.push(this.events[i].date);
      }
      return arr;
    },
    color() {
      const arr = [];
      for (let i = 0; i < this.events.length; i++) {
        arr.push(this.events[i].color);
      }
      return arr;
    },
    attrs() {
      return {
        ...this.defaultAttrs,
        landscape: this.$vuetify.breakpoint.width > 560 ? true : false,
        ...this.$attrs,
      };
    },
  },
};
</script>
