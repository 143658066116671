<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        @click="$emit('getEvent')"
        v-on="on"
      >
        <v-icon>mdi-cog</v-icon>
        ตั้งค่ากิจกรรม
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="font-weight-bold">ตั้งค่ากิจกรรม</span>
      </v-card-title>
      <v-card-title class="justify-space-between">
        <span
          >กิจกรรมประจำวันที่
          <span class="font-weight-bold">{{ formatDateThai(date) }}</span></span
        >
        <v-btn
          v-if="event && event.length > 0"
          color="red"
          dark
          @click="onDelete()"
          >ลบกิจกรรม</v-btn
        >
      </v-card-title>
      <template v-for="(item, i) in form">
        <v-card
          ref="formEvent"
          v-show="!item.status || item.status !== 'deleted'"
          class="mx-7 mb-9 py-4"
          :key="i"
        >
          <v-card-title class="justify-space-between">
            <div></div>
            <div>
              <v-btn class="mr-2" color="primary" @click="addEvents()"
                >เพิ่มกิจกรรม</v-btn
              >
              <v-btn
                v-if="eventForm.length > 1"
                dark
                color="red"
                @click="deleteEvent(i)"
                >ลบ</v-btn
              >
            </div>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form[i].title"
                  label="ชื่อกิจกรรม (ภาษาไทย)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="form[i].description"
                  rows="8"
                  label="รายละเอียด (ภาษาไทย)"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form[i].titleEng"
                  label="ชื่อกิจกรรม (ภาษาอังกฤษ)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="form[i].descriptionEng"
                  label="รายละเอียด (ภาษาอังกฤษ)"
                  rows="8"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-card-title>สีประจำกิจกรรม</v-card-title>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-color-picker
                    v-model="form[i].color"
                    swatches-max-height="200"
                    dot-size="25"
                    mode="hexa"
                    hide-mode-switch
                  ></v-color-picker>
                </v-col>
                <v-col cols="6"> </v-col>
              </v-row>
            </v-container>
            <div class="px-4 py-4">
              <v-btn color="primary" @click="handleFileImport(i)" class="mb-6"
                >เพิ่มรูปภาพ</v-btn
              >
              <input
                ref="uploader"
                class="d-none"
                type="file"
                multiple
                @change="onFileChanged($event, i)"
              />
              <draggable
                v-model="form[i].images"
                class="row"
                @start="drag = true"
                @end="drag = false"
              >
                <v-col
                  class="mt-4"
                  v-for="(img, index) in form[i].images"
                  :key="index"
                  cols="3"
                >
                  <div class="relative">
                    <label class="img-upload">
                      <v-img
                        style="max-width: 20px"
                        :src="require('../../assets/ic_cross.png')"
                        @click="removeImage(i, index)"
                      >
                      </v-img>
                    </label>
                    <v-img
                      height="200"
                      width="200"
                      class="cursor-move"
                      :src="img.src ? img.src : img"
                    ></v-img>
                  </div>
                </v-col>
              </draggable>
            </div>
          </v-container>
        </v-card>
      </template>
      <v-row class="mx-8">
        <v-col cols="12">
          <v-btn block color="primary" @click="onSubmit()"> บันทึก </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn block dark color="secondary" @click="handleDialog()">
            ปิด
          </v-btn>
        </v-col>
        <v-col v-if="eventId" cols="12">
          <v-btn block dark color="red" @click="onDelete()"> ลบกิจกรรม </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import draggable from "vuedraggable";
export default {
  props: {
    event: {
      type: Array,
      default: () => [],
    },
    date: {
      type: String,
      required: true,
    },
  },
  components: {
    draggable,
  },
  mounted() {
    setTimeout(() => {
      if (this.event) {
        this.form = this.event;
        for (let i = 0; i < this.form.length; i++) {
          for (let j = 0; j < this.form[i].images.length; j++) {
            if (this.form[i].images && this.form[i].images[j]) {
              const image = this.form[i].images[j];
              delete this.form[i].images[j];
              this.form[i].images[j] = {
                file: image,
                src: image,
              };
            } else {
              delete this.form[i].images[j];
            }
          }
        }
        this.initialForm = JSON.parse(JSON.stringify(this.form));
      }
    }, 2500);
  },
  computed: {
    attrs() {
      return {
        ...this.$attrs,
      };
    },
    eventId() {
      if (this.event) {
        return this.event._id;
      }
      return null;
    },
    eventForm() {
      if (this.form && this.form.length > 0) {
        return this.form.filter(
          (event) => !event.status || event.status !== "deleted"
        );
      }
      return [];
    },
  },
  data() {
    return {
      dialog: false,
      files: [],
      isSelecting: false,
      radioType: "allDay",
      initialForm: [],
      form: [
        {
          title: "",
          description: "",
          startTime: "00:00",
          endTime: "23:59",
          color: "#38AEDD",
          images: [],
          status: "active",
        },
      ],
    };
  },
  methods: {
    onSubmit() {
      const payload = [];
      for (let i = 0; i < this.form.length; i++) {
        payload.push({
          ...this.form[i],
        });
        payload[i].images = [];
        for (let j = 0; j < this.form[i].images.length; j++) {
          payload[i].images.push(this.form[i].images[j]["file"]);
        }
      }

      this.$emit("input", payload);
      this.dialog = false;
    },
    onDelete() {
      this.$emit("delete", this.date);
      this.dialog = false;
      this.form = [
        {
          title: "",
          description: "",
          startTime: "00:00",
          endTime: "23:59",
          color: "#38AEDD",
          images: [],
          status: "active",
        },
      ];
    },
    deleteEvent(index) {
      // this.form.splice(index, 1);
      this.$nextTick(() => {
        this.form[index].status = "deleted";
      });
      console.log(this.form[index]);
    },
    changeType(event) {
      if (event === "allDay") {
        this.form.startTime = "00:00";
        this.form.endTime = "23:59";
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
      }
    },
    handleFileImport(index) {
      this.isSelecting = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader[index].click();
    },
    onFileChanged(e, index) {
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        this.form[index].images.push({
          file: files[i],
          src: URL.createObjectURL(files[i]),
        });
      }
    },
    removeImage(formIndex, index) {
      this.form[formIndex].images.splice(index, 1);
    },
    handleDialog() {
      this.dialog = !this.dialog;
      this.form = JSON.parse(JSON.stringify(this.initialForm));
    },
    addEvents() {
      this.form.push({
        title: "",
        description: "",
        startTime: "00:00",
        endTime: "23:59",
        color: "#38AEDD",
        images: [],
        status: "active",
      });
    },
    newLine(event) {
      console.log(event);
    },
  },
};
</script>
<style scoped>
.cursor-move {
  cursor: move;
}
.relative {
  position: relative;
}
.img-upload {
  cursor: pointer;
  z-index: 10;
  position: absolute;
  top: -19px;
  right: -19px;
}
</style>
