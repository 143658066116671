<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="disabled"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">ตั้งค่า</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-col cols="12">
              <div class="d-flex justify-center">
                <v-radio-group
                  v-model="itemRadio"
                  @change="changeRadio($event)"
                  row
                >
                  <v-radio label="ฟรีทั้งหมด" value="allFree"></v-radio>
                  <v-radio label="คิดเงิน" value="allPrice"></v-radio>
                  <v-radio label="ราคาเดียว" value="onePrice"></v-radio>
                </v-radio-group>
              </div>
            </v-col>
            <v-row v-if="itemRadio === 'onePrice'">
              <v-col cols="12">
                <v-text-field v-model="onePrice" label="ราคา"></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="schedule.options.price && itemRadio !== 'onePrice'">
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="* ราคาเด็ก"
                  :disabled="itemRadio === 'allFree'"
                  v-model.number="schedule.options.price.young"
                  type="number"
                  hint="กรอกเป็นตัวเลขหากไม่คิดเงินให้กรอก 0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="* ราคานักเรียน / นักศึกษา"
                  :disabled="itemRadio === 'allFree'"
                  v-model.number="schedule.options.price.student"
                  type="number"
                  hint="กรอกเป็นตัวเลขหากไม่คิดเงินให้กรอก 0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="* ราคาผู้ใหญ่"
                  :disabled="itemRadio === 'allFree'"
                  v-model.number="schedule.options.price.adult"
                  type="number"
                  hint="กรอกเป็นตัวเลขหากไม่คิดเงินให้กรอก 0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="* ราคาผู้สูงอายุ"
                  :disabled="itemRadio === 'allFree'"
                  v-model.number="schedule.options.price.senior"
                  type="number"
                  hint="กรอกเป็นตัวเลขหากไม่คิดเงินให้กรอก 0"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card-title>จัดการโปรโมชั่น</v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model.number="schedule.options.promotion.percent"
                  type="number"
                  label="จำนวนเปอร์เซ็นต์ที่ลด"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>* ข้อมูลที่ต้องกรอก</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            ปิด
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSubmit()"> ตกลง </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    schedule: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      required: false,
    },
  },
  computed: {
    attrs() {
      return {
        ...this.$attrs,
      };
    },
  },
  data: () => ({
    itemRadio: null,
    dialog: false,
    onePrice: 0,
    form: {
      options: {
        price: {
          type: "allFree",
          student: 0,
          young: 0,
          adult: 0,
          senior: 0,
        },
        promotion: {
          percent: 0,
        },
      },
    },
  }),
  mounted() {
    setTimeout(() => {
      if (this.form.options.price && this.schedule.options.price) {
        this.itemRadio = this.schedule.options.price.type;
        this.form.options.price.young = this.schedule.options.price.young;
        this.form.options.price.student = this.schedule.options.price.student;
        this.form.options.price.adult = this.schedule.options.price.adult;
        this.form.options.price.senior = this.schedule.options.price.senior;
      }
    }, 100);
  },
  methods: {
    onSubmit() {
      if (this.itemRadio === "onePrice") {
        this.schedule.options.price.young = this.onePrice;
        this.schedule.options.price.student = this.onePrice;
        this.schedule.options.price.adult = this.onePrice;
        this.schedule.options.price.senior = this.onePrice;
        this.itemRadio = "allPrice";
      }
      this.form.scheduleId = this.schedule.scheduleId;
      this.form.options.price.type = this.itemRadio;
      this.schedule.options.price.type = this.itemRadio;
      this.dialog = false;
    },
    changeRadio(event) {
      if (event === "allFree") {
        this.schedule.options.price.young = 0;
        this.schedule.options.price.student = 0;
        this.schedule.options.price.adult = 0;
        this.schedule.options.price.senior = 0;
      }
    },
  },
};
</script>
